/* eslint-disable camelcase */
import type { ApolloQueryResult } from '@apollo/client';
import { FullStory } from '@fullstory/browser';
import { isNil, isNotNil } from '~/utilities/type-guards';
import { getApolloClient } from '~/singlePageApp/getApolloClient';
import type { GetUserDataForFullstoryQuery } from './__gql/getUserDataForFullstory.gql.gen';
import { GetUserDataForFullstoryDocument } from './__gql/getUserDataForFullstory.gql.gen';
import type { FullStoryAnonUserProperties, FullStoryAuthedUserProperties } from './types';
import { setLiveFullStoryProperties } from './utils';

export const setUser = async (
  client = getApolloClient({}, {}, 'AnonymousSchema'),
): Promise<boolean> => {
  const { data, error }: ApolloQueryResult<GetUserDataForFullstoryQuery> = await client.query({
    query: GetUserDataForFullstoryDocument,
  });

  if (isNotNil(error)) {
    throw new Error(`Error: ${JSON.stringify(error)}`);
  }

  // If the current contact is being impersonated, we do not want to
  // instrument this session.
  if (data.currentContact?.isImpersonating === true) {
    FullStory('shutdown');

    // also make it very clear that you're impersonating someone!
    document.body.style.border = '2px solid red';

    return false;
  }

  if (isNil(data.currentUser) || isNil(data.currentAccount)) {
    /**
     * For most anonymous users interacting with the app, we want to shut down FullStory
     * to limit cost. However, for anonymous users (panlists/audience members) interacting
     * with live, we want to set some properties to better understand their experience if things go wrong.
     *
     * Instead of using setIdentity, we will setProperties for these anonymous users.
     * See docs: https://help.fullstory.com/hc/en-us/articles/360020828113-Identifying-users
     *
     */
    const url = new URL(window.location.href);
    const path = url.pathname;

    const regexPatterns = [
      { regex: /^\/live\/join\/([^/]+)\/([^/]+)$/, type: 'panelist' },
      { regex: /^\/live\/events\/([^/]+)$/, type: 'audience' },
    ];

    const match = regexPatterns.find(({ regex }) => regex.test(path));

    if (match) {
      const anonymousLiveUserProperties: Partial<FullStoryAnonUserProperties> = {
        live_participant_type: match.type,
        displayName: null,
        email: null,
      };

      setLiveFullStoryProperties(anonymousLiveUserProperties);

      return true;
    }

    FullStory('shutdown');
    return false;
  }

  FullStory('setIdentity', {
    uid: `${data.currentUser.numericId}`,
    properties: {
      isWadmin_bool: data.currentUser.isWadmin,
      displayName: data.currentUser.name,
      email: data.currentUser.email,
      accountName_str: data.currentAccount.displayName,
      accountKey_str: data.currentAccount.key,
      plan_str: data.currentAccount.plan?.name,
      activeTrial_str: data.currentAccount.activeTrial?.plan.name,
      createdAt_date: data.currentAccount.createdAt,
      videoGoal_str: data.currentContact?.contactFunnelDetails?.videoGoals,
      contactRole_str: data.currentContact?.roleName,
    } satisfies FullStoryAuthedUserProperties,
  });

  return true;
};
