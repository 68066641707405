import styled from 'styled-components';
import { Box, ButtonGroup, mq, Text, useMq, useTheme } from '@wistia/vhs';
import { Helmet } from 'react-helmet-async';
import { INNER_CONTENT_DESKTOP_MAX_WIDTH } from './constants';
import { NavigationTabs } from './NavigationTabs';
import { isEmptyArray, isNonEmptyString } from '~/utilities/type-guards';

const BannerContainer = styled.div`
  width: 100%;
`;

const BreadcrumbContainer = styled(Text)`
  color: ${({ theme }) => theme.color.grey800};
  margin-bottom: 8px;
`;

export const Header = styled(Box)`
  margin: ${({ theme }) => theme.spacing.space05} auto;
  max-width: ${({ contentWidth }) => contentWidth || INNER_CONTENT_DESKTOP_MAX_WIDTH};
  overflow-wrap: anywhere;
  padding: 0 var(--layout-content-spacing);
  width: 100%;

  ${mq.smAndUp} {
    margin: ${({ theme: { spacing }, isEmpty }) => (isEmpty ? spacing.space04 : spacing.space06)}
      auto;
  }
`;

const TitleActions = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.space04};
  justify-content: space-between;
  width: 100%;

  & > :first-child {
    flex-shrink: 0;
    flex-grow: 2;
    max-width: 100%;
  }

  ${mq.smAndDown} {
    & > * {
      flex-basis: 100%;
    }
  }
`;

export const Title = styled(Text)`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spacing.space02};
  max-width: 100%;
  width: max-content;
`;

const Description = styled.p`
  margin-block-end: 0;
  margin-block-start: ${({ theme }) => theme.spacing.space02};
`;

/**
 * @param {object} props
 * @param {string | JSX.Element | null} [props.breadcrumb]
 * @param {boolean} [props.breadcrumbNoStyle]
 * @param {string | JSX.Element} [props.description]
 * @param {string | JSX.Element} [props.pageTitle]
 * @param {import('./NavigationTabs').TabInfo[]} [props.tabs]
 * @param {unknown} [props.topActions]
 * @param {unknown} [props.actions]
 * @param {JSX.Element} [props.additionalScriptTags]
 * @param {JSX.Element | null } [props.trialBannerComponent]
 * @param {string} [props.contentWidth]
 * @param {string} [props.browserTitle]
 * @param {string | JSX.Element | null} [props.thumbnail]
 * @param {JSX.Element | null} [props.banner]
 */
export const ScreenHeader = ({
  breadcrumb = undefined,
  breadcrumbNoStyle = false,
  description,
  pageTitle,
  tabs = undefined,
  topActions = undefined,
  actions,
  contentWidth = INNER_CONTENT_DESKTOP_MAX_WIDTH,
  trialBannerComponent = null,
  browserTitle,
  thumbnail = null,
  additionalScriptTags,
  banner = null,
}) => {
  const theme = useTheme();
  const { isSmAndDown, isSmAndUp } = useMq();

  const isMobileTabs = isSmAndDown;

  const preTitleText = () => {
    return breadcrumbNoStyle ? (
      breadcrumb
    ) : (
      <BreadcrumbContainer
        variant="subtitle2"
        renderAs="span"
        data-testid="breadcrumb-text"
      >
        {breadcrumb}
      </BreadcrumbContainer>
    );
  };

  return (
    <>
      {isMobileTabs && tabs && <NavigationTabs tabs={tabs} />}
      <Header
        contentWidth={contentWidth}
        data-testid="screen-header"
        direction="column"
        fill="horizontal"
        justifyContent="between"
        withTabs={!!tabs}
        gap={isSmAndUp ? '0' : theme.spacing.space03}
        isEmpty={!isNonEmptyString(pageTitle) && isEmptyArray(actions)}
      >
        {browserTitle && (
          <Helmet>
            {additionalScriptTags}
            <title>{browserTitle}</title>
          </Helmet>
        )}
        {topActions && (
          <Box
            data-testid="screen-header-top-actions"
            alignItems="start"
          >
            {topActions}
          </Box>
        )}

        {breadcrumb && <Box>{preTitleText()}</Box>}

        <TitleActions data-testid="screen-header--title-and-buttons">
          <Title
            variant="headline1"
            renderAs="h1"
            data-testid="page-title-text"
          >
            {thumbnail}

            {pageTitle}
          </Title>
          <ButtonGroup align="right">{actions}</ButtonGroup>
          {trialBannerComponent}
        </TitleActions>

        {description && <Description>{description}</Description>}

        {banner && <BannerContainer>{banner}</BannerContainer>}

        {!isMobileTabs && tabs && <NavigationTabs tabs={tabs} />}
      </Header>
    </>
  );
};
